export default Object.freeze({
  SORT_DESC_DEFAULT: false,
  PER_PAGE_DEFAULT: 15,
  FILTER_SEARCH: 'filterSearch',
  FILTER_LIST: 'filterList',
  FILTER_SWITCH: 'filterSwitch',
  // To match with api keys, values
  ITEMS_PER_PAGE: 'itemsPerPage',
  CURRENT_PAGE: 'page',
  ORDER: 'order',
  SORT_ASC: 'asc',
  SORT_DESC: 'desc'
})

import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppFilterIcon',
  className: 'app-filter',
  defaultAttrs: {
    viewBox: '0 0 24 24'
  },
  pathAttrs: [
    {
      d: 'M21 4V6H20L15 13.5V22H9V13.5L4 6H3V4H21ZM6.4037 6L11 12.8944V20H13V12.8944L17.5963 6H6.4037Z'
    }
  ]
})

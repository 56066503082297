import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppFilterOffIcon',
  className: 'app-filter-off',
  defaultAttrs: {
    viewBox: '0 0 24 24'
  },
  pathAttrs: [
    {
      d: 'M6.92893 0.514648L21.0711 14.6568L19.6569 16.071L15.834 12.2486L15 13.4999V21.9999H9V13.4999L4 5.99993H3V3.99993L7.585 3.99965L5.51472 1.92886L6.92893 0.514648ZM9.585 5.99965L6.4037 5.99993L11 12.8944V19.9999H13V12.8944L14.392 10.8066L9.585 5.99965ZM21 3.99993V5.99993H20L18.085 8.87193L16.643 7.42893L17.5963 5.99993H15.213L13.213 3.99993H21Z'
    }
  ]
})

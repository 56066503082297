import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppMonetIcon',
  className: 'app-monet',
  defaultAttrs: {
    viewBox: '0 0 24 24'
  },
  pathAttrs: [
    {
      d: 'M3 12H7V21H3V12ZM17 8H21V21H17V8ZM10 2H14V21H10V2Z'
    }
  ]
})

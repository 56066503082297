import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppCompassIcon',
  className: 'app-compass',
  defaultAttrs: {
    'stroke-width': 1,
    stroke: 'currentColor'
  },
  pathAttrs: [
    {
      'fill-rule': 'evenodd',
      'clip-rule': 'evenodd',
      d: 'M25 2.75C12.72 2.75 2.75 12.72 2.75 25S12.72 47.25 25 47.25 47.25 37.28 47.25 25 37.28 2.75 25 2.75zm0 1.534c11.433 0 20.715 9.283 20.715 20.716 0 11.433-9.282 20.716-20.715 20.716S4.285 36.433 4.285 25C4.285 13.567 13.567 4.284 25 4.284zm.038 34.942h-.006a.768.768 0 0 0-.767.767v3.07c0 .423.344.766.767.766h.006a.766.766 0 0 0 .762-.767v-3.069a.767.767 0 0 0-.762-.767zm4.592-9.176a.73.73 0 0 0 .243-.158l.019-.019a.734.734 0 0 0 .158-.243l6.506-15.178a.771.771 0 0 0-.163-.845.772.772 0 0 0-.845-.163l-15.19 6.511a.773.773 0 0 0-.403.404l-6.511 15.189a.772.772 0 0 0 .162.846.771.771 0 0 0 .846.162l15.177-6.506zm-14.02 4.34 12.387-5.309-7.079-7.078-5.307 12.386zm6.393-13.472 7.079 7.079 5.307-12.387-12.386 5.308zm17.99 4.881h3.076a.768.768 0 0 0-.006-1.534h-3.07a.768.768 0 0 0 0 1.534zm-32.99 0h3.074a.766.766 0 0 0-.006-1.534H7.002a.768.768 0 0 0 0 1.534zM25.038 6.235h-.006a.768.768 0 0 0-.768.767v3.07c0 .423.344.767.768.767h.006a.766.766 0 0 0 .76-.768V7.002a.767.767 0 0 0-.76-.767z'
    }
  ]
})

import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppLaptopIcon',
  className: 'app-laptop',
  defaultAttrs: {
    viewBox: '0 0 700 700'
  },
  pathAttrs: [
    {
      d: 'M30.4 567.381a22.809 22.809 0 0 0-16.232 6.69 22.81 22.81 0 0 0-6.689 16.232 22.816 22.816 0 0 0 6.746 16.124 22.852 22.852 0 0 0 16.175 6.631h639.287c12.55-.044 22.711-10.205 22.755-22.755a22.83 22.83 0 0 0-22.756-22.921H30.401zM82.817 86.86a22.83 22.83 0 0 0-22.921 22.756v394.231a22.82 22.82 0 0 0 6.689 16.233 22.836 22.836 0 0 0 16.232 6.689H617.27a22.858 22.858 0 0 0 16.124-6.747 22.825 22.825 0 0 0 6.632-16.175v-394.23c-.044-12.55-10.206-22.712-22.755-22.756H82.817zm22.755 45.678h488.773v348.55H105.572v-348.55z'
    }
  ]
})

export default {
  en: {
    hour: {
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23'
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    dayHour: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23'
    },
    monthDayHour: {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23'
    },
    monthDay: {
      month: 'short',
      day: 'numeric'
    },
    yearMonth: {
      year: 'numeric',
      month: 'short'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      weekday: 'long',
      hourCycle: 'h23'
    },
    longWithoutHour: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'long'
    },
    longNumeric: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    firstDayOfWeek: 0, // Sunday
    shortHumanizer: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms'
    }
  },
  fr: {
    hour: {
      hour: 'numeric',
      minute: 'numeric'
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    dayHour: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    monthDayHour: {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    monthDay: {
      month: 'short',
      day: 'numeric'
    },
    yearMonth: {
      year: 'numeric',
      month: 'short'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      weekday: 'long'
    },
    longWithoutHour: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'long'
    },
    longNumeric: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    firstDayOfWeek: 1, // Monday
    shortHumanizer: {
      y: () => 'an(s)',
      mo: () => 'mois',
      w: () => 'sem.',
      d: () => 'j',
      h: () => 'h',
      m: () => 'min',
      s: () => 's',
      ms: () => 'ms'
    }
  }
}

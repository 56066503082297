import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppProspectIcon',
  className: 'app-prospect',
  defaultAttrs: {
    viewBox: '0 0 24 24'
  },
  pathAttrs: [
    {
      d: 'M20.95 11h1.55v2h-1.55c-.45 4.17-3.78 7.5-7.95 7.95v1.55h-2v-1.55C6.83 20.5 3.5 17.17 3.05 13H1.5v-2h1.55C3.5 6.83 6.83 3.5 11 3.05V1.5h2v1.55c4.17.45 7.5 3.78 7.95 7.95M5.07 11H6.5v2H5.07A6.98 6.98 0 0 0 11 18.93V17.5h2v1.43A6.98 6.98 0 0 0 18.93 13H17.5v-2h1.43A6.98 6.98 0 0 0 13 5.07V6.5h-2V5.07A6.98 6.98 0 0 0 5.07 11M16 16H8v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1m-4-8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2Z'
    }
  ]
})

import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppFolderIcon',
  className: 'app-folder',
  pathAttrs: [
    {
      d: 'M2.9 43h38.8c.6 0 1.2-.5 1.3-1l5.5-22.1c.1-.4 0-.9-.2-1.2-.3-.3-.7-.5-1.1-.5H43V14c0-.8-.6-1.4-1.4-1.4H22.8l-5.1-5.1c-.3-.3-.6-.4-1-.4H2.9c-.8-.1-1.4.5-1.4 1.3v33.2c0 .8.6 1.4 1.4 1.4zm42.5-22.2-4.9 19.4H4.6l4.9-19.4h35.9zM4.2 9.8h11.9l5.1 5.1c.3.3.6.4 1 .4h18v2.8H8.4c-.6 0-1.2.4-1.3 1L4.2 30.4V9.8z'
    }
  ]
})

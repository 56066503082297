import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppOrganizationIcon',
  className: 'app-organization',
  defaultAttrs: {
    viewBox: '0 0 24 24'
  },
  pathAttrs: [
    {
      d: 'M1 23h4.7c.2 0 .4-.1.6-.2.1-.1.2-.3.2-.6v-4.7c0-.2-.1-.4-.2-.6-.1-.1-.3-.2-.6-.2H4.2v-3.9h7.1v3.9H9.6c-.2 0-.4.1-.6.2-.1.1-.2.3-.2.6v4.7c0 .2.1.4.2.6.2.1.4.2.6.2h4.7c.2 0 .4-.1.6-.2.1-.1.2-.3.2-.6v-4.7c0-.2-.1-.4-.2-.6-.1-.1-.3-.2-.6-.2h-1.6v-3.9h7v3.9h-1.6c-.2 0-.4.1-.6.2s-.2.3-.2.6v4.7c0 .2.1.4.2.6.1.1.3.2.6.2H23c.2 0 .4-.1.6-.2.1-.1.2-.3.2-.6v-4.7c0-.2-.1-.4-.2-.6s-.3-.2-.6-.2h-1.6V12c0-.2-.1-.4-.2-.6-.1-.1-.3-.2-.6-.2h-7.8V7.3h1.6c.2 0 .4-.1.6-.2.1-.1.2-.3.2-.6V1.8c0-.2-.1-.4-.2-.6-.2-.1-.4-.2-.6-.2H9.6c-.2 0-.4.1-.5.3-.2.1-.2.3-.2.5v4.7c0 .2.1.4.2.6.1.1.3.2.6.2h1.6v3.9H3.4c-.2 0-.4.1-.6.2-.1.1-.2.3-.2.6v4.7H1c-.2 0-.4.1-.6.2s-.2.3-.2.6v4.7c0 .2.1.4.2.6.2.1.4.2.6.2zm21.2-4.7v3.1H19v-3.1h3.2zM10.4 5.7V2.6h3.1v3.1h-3.1zm3.2 12.6v3.1h-3.1v-3.1h3.1zm-11.8 0h3.1v3.1H1.8v-3.1z'
    }
  ]
})

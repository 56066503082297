import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppBuildingIcon',
  className: 'app-building',
  defaultAttrs: {
    viewBox: '0 0 24 24'
  },
  pathAttrs: [
    {
      d: 'M6.3 9.4h2.1v5.2H6.3zm0-5.9h2.1v5.2H6.3zm0 11.8h2.1v5.2H6.3zm3.2-5.9h2.1v5.2H9.5zm0-5.9h2.1v5.2H9.5zm0 11.8h2.1v5.2H9.5zM3.1 9.4h2.1v5.2H3.1zm0-5.9h2.1v5.2H3.1zm0 11.8h2.1v5.2H3.1zm12.5-5.9h2.1v5.2h-2.1zm0 5.9h2.1v5.2h-2.1zm3.2-5.9h2.1v5.2h-2.1zm0 5.9h2.1v5.2h-2.1z'
    },
    {
      d: 'M14.7 6.4V.5H0v23.1h24V6.4h-9.3zm-1.6 15.5H1.5V2.1H13v19.8zm9.2 0h-7.6v-14h7.6v14z'
    }
  ]
})

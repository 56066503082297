import { createApp } from '@vue/composition-api'
import store from '@store'
import router from '@router'
import axios from '@axios'
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'
import * as auth from '@auth'
import * as services from '@services'
import App from './App.vue'

// Progress bar for axios requests
loadProgressBar({ showSpinner: false }, axios)

export default () => {
  // Make router, auth, axios, services instances available in store
  store.$router = router
  store.$auth = auth
  store.$axios = axios
  store.$services = services

  // Create the app instantiation Object.
  const app = createApp({
    router,
    store,
    render: h => h(App)
  })

  // Unlock any locked config when user close page
  let exiting = false

  document.addEventListener('pagehide', async () => {
    exiting = true
    await store.dispatch('websiteConfig/unlock')
  })

  document.addEventListener('unload', async () => {
    exiting = true
    await store.dispatch('websiteConfig/unlock')
  })

  document.addEventListener('visibilitychange', async () => {
    if (exiting) await store.dispatch('websiteConfig/unlock')
  })

  // Expose the app, the router and the store.
  return {
    app,
    store,
    router
  }
}

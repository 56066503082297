import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppConsoleIcon',
  className: 'app-console',
  defaultAttrs: {
    viewBox: '0 0 700 450'
  },
  pathAttrs: [
    {
      d: 'M676.27 436.115H23.73c-9.812 0-17.77-7.963-17.77-17.77V31.655c0-9.807 7.963-17.77 17.77-17.77h652.54c9.807 0 17.77 7.963 17.77 17.77v386.69c0 9.807-7.958 17.77-17.77 17.77zM41.5 400.575H658.5V49.424H41.5v351.15z'
    },
    {
      d: 'M533.254 351.387H166.759c-9.808 0-17.77-7.963-17.77-17.77V116.396c0-9.808 7.962-17.77 17.77-17.77h366.495c9.807 0 17.77 7.962 17.77 17.77V333.63c0 9.807-7.958 17.765-17.77 17.765v-.008zm-348.724-35.54h330.953v-181.68H184.53v181.68zM625.23 259.539c0 11.701-9.486 21.182-21.183 21.182-11.702 0-21.183-9.48-21.183-21.182 0-11.697 9.48-21.183 21.183-21.183 11.696 0 21.182 9.485 21.182 21.183M625.23 328.78c0 11.697-9.486 21.183-21.183 21.183-11.702 0-21.183-9.485-21.183-21.182 0-11.702 9.48-21.187 21.183-21.187 11.696 0 21.182 9.486 21.182 21.187M117.128 259.539c0 11.701-9.48 21.182-21.182 21.182-11.697 0-21.183-9.48-21.183-21.182 0-11.697 9.485-21.183 21.183-21.183 11.701 0 21.182 9.485 21.182 21.183M117.128 328.78c0 11.697-9.48 21.183-21.182 21.183-11.697 0-21.183-9.485-21.183-21.182 0-11.702 9.485-21.187 21.183-21.187 11.701 0 21.182 9.486 21.182 21.187'
    }
  ]
})

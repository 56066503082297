import mergeProps from '@vue/babel-helper-vue-jsx-merge-props'

/**
 * Helper for generate functional icon
 * @param {string} name
 * @param {string} className
 * @param {Object} [defaultAttrs={}]
 * @param {Array} [pathAttrs=[]]
 * @returns {{functional: boolean, name, render(*, *): *, props: {size: {default: string, validator: (function(*): *), type: StringConstructor}}}}
 */
// eslint-disable-next-line import/prefer-default-export
export const generateIcon = (
  {
    name,
    className = '',
    defaultAttrs = {},
    pathAttrs = []
  }
) => ({
  name,
  props: {
    size: {
      type: String,
      default: '24',
      validator: function validator (s) {
        return !Number.isNaN(s) || (s.length >= 2 && !Number.isNaN(s.slice(0, s.length - 1)) && s.slice(-1) === 'x')
      }
    }
  },
  functional: true,
  render (h, ctx) {
    const size = ctx.props.size.slice(-1) === 'x'
      ? `${ctx.props.size.slice(0, ctx.props.size.length - 1)}em`
      : `${parseInt(ctx.props.size, 10)}px`
    const attrs = ctx.data.attrs || {}
    attrs.width = attrs.width || size
    attrs.height = attrs.height || size
    // eslint-disable-next-line no-param-reassign
    ctx.data.attrs = attrs

    return h(
      'svg',
      mergeProps([
        {
          attrs: {
            xmlns: 'http://www.w3.org/2000/svg',
            width: '24',
            height: '24',
            viewBox: '0 0 50 50',
            stroke: 'none',
            fill: 'currentColor',
            ...defaultAttrs
          },
          class: `feather feather-${className}`
        }, ctx.data]),
      pathAttrs.map(item => h('path', {
        attrs: item
      }))
    )
  }
})

import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppEmbeddedIcon',
  className: 'app-embedded',
  defaultAttrs: {
    viewBox: '0 0 100 100'
  },
  pathAttrs: [
    {
      d: 'M69.001 78.499a3.427 3.427 0 0 1-2.424-5.851L89.225 50 66.578 27.353a3.427 3.427 0 1 1 4.847-4.848l25.07 25.071a3.427 3.427 0 0 1 0 4.847l-25.07 25.071A3.417 3.417 0 0 1 69 78.5zM30.999 78.499a3.417 3.417 0 0 1-2.424-1.005l-25.07-25.07a3.427 3.427 0 0 1 0-4.848l25.07-25.07a3.427 3.427 0 1 1 4.847 4.847L10.776 50l22.646 22.647A3.427 3.427 0 0 1 31 78.5zM37.544 87.681a3.427 3.427 0 0 1-3.221-4.6l24.911-68.505a3.426 3.426 0 0 1 4.393-2.05 3.427 3.427 0 0 1 2.05 4.393L40.766 85.424a3.428 3.428 0 0 1-3.222 2.257z'
    }
  ]
})

export default Object.freeze({
  article: {
    value: 'article',
    label: 'Article',
    color: '#7491d2'
  },
  native: {
    value: 'native',
    label: 'Native',
    color: '#28c76f'
  },
  video: {
    value: 'video',
    label: 'Outstream',
    color: '#ea5455'
  },
  banner: {
    value: 'banner',
    label: 'Display',
    color: '#ff9f43'
  },
  dynamic: {
    value: 'dynamic',
    label: 'Dynamic',
    color: '#cb68ae'
  }
})

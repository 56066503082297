export default Object.freeze({
  ACTIVE: {
    isActive: true,
    icon: 'CheckIcon',
    class: 'text-success'
  },
  INACTIVE: {
    icon: 'XIcon',
    class: 'text-danger',
    tooltip: 'bidders.inactiveInformation'
  },
  MIXED: {
    icon: 'AlertCircleIcon',
    class: 'text-warning',
    tooltip: 'bidders.mixedInformation'
  },
  MISSING: {
    icon: 'XIcon',
    class: 'text-body'
  }
})

import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppZoomIcon',
  className: 'app-zoom',
  defaultAttrs: {
    viewBox: '0 0 700 700'
  },
  pathAttrs: [
    {
      d: 'm272.95519,5.18172c-147.65139,0 -267.83593,120.18864 -267.83593,267.83593c0,115.49741 73.42257,214.10476 176.25244,251.57446c5.83431,2.6636 12.5175,2.765 18.43148,0.27758c5.91439,-2.48746 10.52103,-7.33433 12.69898,-13.36611c2.17794,-6.03724 1.73478,-12.70431 -1.22773,-18.39459c-2.96259,-5.69561 -8.16708,-9.88588 -14.35929,-11.5619c-85.07617,-31.00338 -145.87492,-112.44873 -145.87492,-208.5294c0,-122.83694 99.08289,-221.9212 221.9212,-221.9212s221.9212,99.08289 221.9212,221.9212s-99.08289,221.9212 -221.9212,221.9212c-6.14396,-0.08541 -12.06901,2.29533 -16.44595,6.6083c-4.37174,4.31844 -6.83788,10.20619 -6.83788,16.35029c0,6.14396 2.46614,12.03704 6.83788,16.35029c4.37707,4.31298 10.30225,6.69371 16.44595,6.6083c65.62937,0 125.7886,-23.78547 172.42621,-63.13139l209.23999,209.23999c4.16895,4.77746 10.13677,7.6172 16.47328,7.84144c6.34143,0.22953 12.49072,-2.17794 16.99665,-6.65108c4.49992,-4.4678 6.95526,-10.60111 6.77379,-16.94199c-0.17616,-6.34143 -2.97325,-12.32524 -7.71859,-16.53204l-209.23999,-209.23999c39.28169,-46.61575 62.89088,-106.84877 62.89088,-172.42621c0,-147.65139 -120.18864,-267.83593 -267.83593,-267.83593l-0.01252,0.00283z'
    }
  ]
})

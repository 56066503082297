export default Object.freeze({
  CITY: 'Paris',
  DOMAIN: 'domain.com',
  EMAIL: 'jane@domain.com',
  FIRST_NAME: 'Jane',
  JS_SOURCE: '//actirise.dev/main.js',
  LAST_NAME: 'Doe',
  POSTAL_CODE: '75000',
  URL: 'https://www.domain.com',
  VAT: 'FRXXXXXXXXXXXX',
  XPATH: '//'
})

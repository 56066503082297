export default Object.freeze({
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  PARTIAL_CONTENT: 206,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  TEAPOT: 418,
  UNPROCESSABLE_ENTITY: 422,
  TOKEN_EXPIRED: 498,
  INTERNAL_SERVER_ERROR: 500
})

import {
  state as stateWebsites,
  getters as gettersWebsites,
  mutations as mutationsWebsites,
  actions as actionsWebsites
} from '@store/modules/websites'

export const state = {
  ...stateWebsites,
  tableColumnFilters: {
    active: true,
    validated: false
  }
}

export const getters = {
  ...gettersWebsites
}

export const mutations = {
  ...mutationsWebsites
}

export const actions = {
  ...actionsWebsites
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppDeviceIcon',
  className: 'app-device',
  defaultAttrs: {
    viewBox: '0 0 512 512'
  },
  pathAttrs: [
    {
      d: 'M472 232h-48V120a24.028 24.028 0 0 0-24-24H40a24.028 24.028 0 0 0-24 24v246a24.028 24.028 0 0 0 24 24h172v50h-60v32h152v-32h-60v-50h92v58a24.027 24.027 0 0 0 24 24h112a24.027 24.027 0 0 0 24-24V256a24.027 24.027 0 0 0-24-24Zm-136 24v102H48V128h344v104h-32a24.027 24.027 0 0 0-24 24Zm128 184h-96V264h96Z'
    }
  ]
})

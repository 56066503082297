import {
  state as stateAccounts,
  getters as gettersAccounts,
  mutations as mutationsAccounts,
  actions as actionsAccounts
} from '@store/modules/accounts'

/** @typedef {import('./modules/accounts').State} State */

export const state = {
  ...stateAccounts,
  tableColumnFilters: {},
  editedItemId: null
}

export const getters = {
  ...gettersAccounts
}

export const mutations = {
  ...mutationsAccounts,
  /**
   * @param {State} state
   * @param {string, number} id
   */
  setEditedItemId (state, id) {
    state.editedItemId = id
  }
}

export const actions = {
  ...actionsAccounts
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

// In relation with vueSocial => https://www.growthbunker.dev/vuesocial/?ref=madewithvuejs.com
export default Object.freeze({
  '500px': '500px',
  'Actirise Feed': 'actirise',
  Airbnb: 'airbnb',
  Amazon: 'amazon',
  Android: 'android',
  'Android Browser': 'android',
  Apple: 'apple',
  'Avast Secure Browser': 'avast-secure-browser',
  'AVG Secure Browser': 'avast-secure-browser',
  Bankin: 'bankin',
  Behance: 'behance',
  Bing: 'bing',
  Bitly: 'bitly',
  Blackberry: 'blackberry',
  Blogger: 'blogger',
  Brave: 'brave',
  Buffer: 'buffer',
  Chrome: 'chrome',
  'Chrome Headless': 'chrome',
  'Chrome WebView': 'chrome',
  Chromium: 'chrome',
  Codepen: 'codepen',
  'com.pinterest': 'pinterest',
  Dailymotion: 'dailymotion',
  Dribbble: 'dribbble',
  Drive: 'drive',
  Dropbox: 'dropbox',
  DuckDuckGo: 'duckduckgo',
  Edge: 'edge',
  Electron: 'electron',
  Envato: 'envato',
  Evernote: 'evernote',
  Facebook: 'facebook',
  Fancy: 'fancy',
  Feedly: 'feedly',
  Firefox: 'firefox',
  'Firefox Focus': 'firefox',
  Flickr: 'flickr',
  Flipboard: 'flipboard',
  'flipboard.com': 'flipboard',
  Foursquare: 'foursquare',
  Github: 'github',
  Gmail: 'gmail',
  Google: 'google',
  'Google Discover': 'googlediscover',
  'Google News': 'google',
  'Google+': 'google',
  GSA: 'google',
  Hangout: 'hangout',
  'Huawei Browser': 'huawei',
  IE: 'internet-explorer',
  ie: 'internet-explorer',
  IEMobile: 'internet-explorer',
  Instagram: 'instagram',
  Internetexplorer: 'internet-explorer',
  Invision: 'invision',
  LinkedIn: 'linkedin',
  'm.facebook.com': 'facebook',
  Magento: 'magento',
  Maxthon: 'maxthon',
  Medium: 'medium',
  Messenger: 'messenger',
  'Mobile Safari': 'safari',
  Msn: 'msn',
  none: 'none',
  Opera: 'opera',
  opera: 'opera',
  'Opera Touch': 'opera-touch',
  Paypal: 'paypal',
  Periscope: 'periscope',
  Photoshop: 'photoshop',
  Pinterest: 'pinterest',
  Pocket: 'pocket',
  Principle: 'principle',
  Producthunt: 'producthunt',
  Qwant: 'qwant',
  Rdio: 'rdio',
  Reddit: 'reddit',
  Rss: 'rss',
  Safari: 'safari',
  'Samsung Browser': 'samsung-internet',
  Scoopit: 'scoopit',
  Shopify: 'shopify',
  Sketch: 'sketch',
  Skype: 'skype',
  Slack: 'slack',
  Snapchat: 'snapchat',
  Soundcloud: 'soundcloud',
  Spotify: 'spotify',
  Stackoverflow: 'stackoverflow',
  Taboola: 'taboola',
  TikTok: 'tikTok',
  Tinder: 'tinder',
  Trello: 'trello',
  Tumblr: 'tumblr',
  Twitter: 'twitter',
  Upday: 'upday',
  Viadeo: 'viadeo',
  Viber: 'viber',
  Vimeo: 'vimeo',
  Vine: 'vine',
  WebKit: 'webkit',
  WeChat: 'weChat',
  WhatsApp: 'whatsapp',
  Windowsphone: 'windowsphone',
  Wordpress: 'wordpress',
  'Yahoo!': 'yahoo',
  'Yahoo! Mail': 'yahoo',
  Yandex: 'yandex',
  Yelp: 'yelp',
  Youtube: 'youtube'
})

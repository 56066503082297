import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppDragDropIcon',
  className: 'app-drag-drop',
  defaultAttrs: {
    viewBox: '0 0 1024 1024'
  },
  pathAttrs: [
    {
      d: 'M389.12 650.752h245.76c8.062 0.615 14.884 5.39 18.373 12.162l0.059 0.126c1.325 1.932 2.117 4.321 2.117 6.895 0 0.457-0.025 0.908-0.074 1.352l0.005-0.055c-0.084 5.306-1.596 10.243-4.168 14.462l0.072-0.126-122.88 163.84c-3.776 4.997-9.707 8.192-16.384 8.192s-12.608-3.195-16.347-8.14l-0.037-0.052-122.88-163.84c-2.595-3.622-4.149-8.144-4.149-13.028 0-3.448 0.775-6.715 2.159-9.637l-0.058 0.137c3.548-6.898 10.37-11.673 18.356-12.283l0.076-0.005zM634.88 245.248h-245.76c-8.062-0.615-14.884-5.39-18.373-12.162l-0.059-0.126c-1.325-1.932-2.117-4.321-2.117-6.895 0-0.457 0.025-0.908 0.074-1.352l-0.005 0.055c0.084-5.306 1.596-10.243 4.168-14.462l-0.072 0.126 122.88-163.84c3.776-4.997 9.707-8.192 16.384-8.192s12.608 3.195 16.347 8.14l0.037 0.052 122.88 163.84c2.595 3.622 4.149 8.144 4.149 13.028 0 3.448-0.775 6.715-2.159 9.637l0.058-0.137c-3.548 6.898-10.37 11.673-18.356 12.283l-0.076 0.005zM131.072 538.112h761.856c15.835 0 28.672 12.837 28.672 28.672v0c0 15.835-12.837 28.672-28.672 28.672h-761.856c-15.835 0-28.672-12.837-28.672-28.672v0c0-15.835 12.837-28.672 28.672-28.672v0zM892.928 357.888h-761.856c-15.835 0-28.672-12.837-28.672-28.672v0c0-15.835 12.837-28.672 28.672-28.672v0h761.856c15.835 0 28.672 12.837 28.672 28.672v0c0 15.835-12.837 28.672-28.672 28.672v0zM892.928 476.672h-761.856c-15.835 0-28.672-12.837-28.672-28.672s12.837-28.672 28.672-28.672h761.856c15.835 0 28.672 12.837 28.672 28.672s-12.837 28.672-28.672 28.672v0z'
    }
  ]
})

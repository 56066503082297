import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppMobileIcon',
  className: 'app-mobile',
  defaultAttrs: {
    viewBox: '0 0 512 512'
  },
  pathAttrs: [
    {
      d: 'M104 48v416a32.036 32.036 0 0 0 32 32h248a32.036 32.036 0 0 0 32-32V48a32.036 32.036 0 0 0-32-32H136a32.036 32.036 0 0 0-32 32Zm280.021 416H136V48h248Z'
    },
    {
      d: 'M216 80h96v32h-96zm32 312h32v32h-32z'
    }
  ]
})

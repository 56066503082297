export default Object.freeze({
  BEFORE_YOU_LEAVE: 'beforeyouleave',
  CORNER: 'corner',
  FEED: 'feed',
  INTERSTITIAL: 'interstitial',
  PARALLAX: 'parallax',
  SKIN_DESKTOP: 'skin_desktop',
  SLIDEUP: 'slideup',
  SLIDEUP_FEED: 'slideupfeed'
})

export default Object.freeze({
  console: 'AppConsoleIcon',
  desktop: 'AppDesktopIcon',
  devices: 'AppDeviceIcon',
  embedded: 'AppEmbeddedIcon',
  mobile: 'AppMobileIcon',
  smarttv: 'AirplayIcon',
  tablet: 'TabletIcon',
  wearable: 'WatchIcon'
})

import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppPieIcon',
  className: 'app-pie',
  defaultAttrs: {
    viewBox: '0 0 24 24'
  },
  pathAttrs: [
    {
      d: 'M9 2.4578V4.58152C6.06817 5.76829 4 8.64262 4 12C4 16.4183 7.58172 20 12 20C15.3574 20 18.2317 17.9318 19.4185 15H21.5422C20.2679 19.0571 16.4776 22 12 22C6.47715 22 2 17.5228 2 12C2 7.52236 4.94289 3.73207 9 2.4578ZM12 2C17.5228 2 22 6.47715 22 12C22 12.3375 21.9833 12.6711 21.9506 13H11V2.04938C11.3289 2.01672 11.6625 2 12 2ZM13 4.06189V11H19.9381C19.4869 7.38128 16.6187 4.51314 13 4.06189Z'
    }
  ]
})

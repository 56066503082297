import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppActiriseIcon',
  className: 'app-actirise',
  defaultAttrs: {
    viewBox: '0 0 50 50'
  },
  pathAttrs: [
    {
      d: 'M36.666 0H13.333C5.969 0 0 5.97 0 13.333v23.333C0 44.029 5.969 50 13.333 50h23.333C44.029 50 50 44.029 50 36.666V13.333C50 5.97 44.029 0 36.666 0zM47 36.666C47 42.364 42.364 47 36.666 47H13.333C7.635 47 3 42.364 3 36.666V13.333C3 7.635 7.635 3 13.333 3h23.333C42.364 3 47 7.635 47 13.333v23.333z'
    },
    {
      d: 'm16.756 37.953-4.713-4.715L23.24 22.044l4.713 4.715zM12.043 12.043v6.667h19.246l-7.267-6.667zM31.289 18.71v19.247h6.666V25.356l-6.666-6.671z'
    }
  ]
})

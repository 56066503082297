import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppDesktopIcon',
  className: 'app-desktop',
  defaultAttrs: {
    viewBox: '0 0 512 512'
  },
  pathAttrs: [
    {
      d: 'M40 392h200v72h-80v32h192v-32h-80v-72h200a24.028 24.028 0 0 0 24-24V72a24.028 24.028 0 0 0-24-24H40a24.028 24.028 0 0 0-24 24v296a24.028 24.028 0 0 0 24 24Zm8-312h416v280H48Z'
    }
  ]
})

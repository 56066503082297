import { generateIcon } from '@assets/icons/helperIcon'

export default generateIcon({
  name: 'AppPulseIcon',
  className: 'app-pulse',
  defaultAttrs: {
    viewBox: '0 0 24 24'
  },
  pathAttrs: [
    {
      d: 'M9 7.53861L15 21.5386L18.6594 13H23V11H17.3406L15 16.4614L9 2.46143L5.3406 11H1V13H6.6594L9 7.53861Z'
    }
  ]
})

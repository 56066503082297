export default Object.freeze({
  BIDDER_ACTIVE: 'bidder.active',
  BIDDER_NAME: 'bidder.name',
  VIOLATIONS: {
    DUPLICATES: 'duplicates',
    ERRORS: 'errors',
    NOTICES: 'notices',
    WARNINGS: 'warnings'
  }
})
